.colored-toast .swal2-title {
    font-size: 17px !important;
    }
  .colored-toast.swal2-icon-success {
      background-color: rgb(153, 153, 153) !important;
      /* background-color: #77d044 !important; */
    }
    
    .colored-toast.swal2-icon-error {
      background-color: #f27474 !important;
    }
    
    .colored-toast.swal2-icon-warning {
      background-color: #f8bb86 !important;
    }
    
    .colored-toast.swal2-icon-info {
      background-color: #3fc3ee !important;
    }
    
    .colored-toast.swal2-icon-question {
      background-color: #87adbd !important;
    }
    
    .colored-toast .swal2-title {
      color: white;
    }
    
    .colored-toast .swal2-close {
      color: white;
    }
    
    .colored-toast .swal2-html-container {
      color: white;
    }