@font-face {
    font-family: 'Mentalist';
    src: url('../fonts/Mentalist-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Nexa-Heavy';
    src: url('../fonts/Nexa-Heavy.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Nexa-ExtraLight';
    src: url('../fonts/Nexa-ExtraLight.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

button {
    font-family: 'Mentalist', sans-serif !important;
    font-size: x-small !important;
}

input {
    font-family: 'Nexa-ExtraLight', sans-serif !important;
}

.nexa-ExtraLight-front {

    font-family: 'Nexa-ExtraLight', sans-serif !important;
}

.nexa-Heavy-front {

    font-family: 'Nexa-Heavy', sans-serif;
}

.editor-wrapper {
    min-height: 300px;
    max-height: 300px;
    border: 1px solid #ddd;
    border-radius: 0.5rem;
    background-color: #fff;
    overflow: auto;
    position: relative;
}

.demo-wrapper {
    height: 100%;
}

.sticky-toolbar {
    z-index: 10;
    background-color: white;
    border-bottom: 1px solid #ddd;
}

.editor-content {
    height: calc(100% - 60px);
    overflow-y: auto;
}

.public-DraftEditor-content {
    color: #000000 !important;
}

.rdw-editor-main {
    padding-top: 20px;
    padding-bottom: 20px;
}