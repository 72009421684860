a {
  color: #dcdcdc !important;
}

.gradient {
  color: transparent;
  background: linear-gradient(90deg,
      rgba(255, 255, 255, 0.5),
      #fff 50.36%,
      rgba(153, 153, 153, 0.5));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}

.sideBarGradient {
  color: transparent;
  background: linear-gradient(90deg,
      rgba(255, 255, 255, 0.5),
      #fff 50.36%,
      rgba(153, 153, 153, 0.5));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}

.sideBarGradient:hover {
  -webkit-text-fill-color: black;
  /* Change text color on hover */
  text-fill-color: black;
  background: white;
}


.swal2-modal {
  background: #141417;
  box-shadow: 0px 4px 20px 2px rgba(255, 255, 255, 0.76);
  border-radius: 40px;
  color: #DCDCDC;
}

.swal2-actions button {
  background: #D9D9D9 !important;
  border-radius: 200px !important;
  color: #101010 !important;
}

.swal2-actions button:hover {
  background: #101010 !important;
  color: #DCDCDC !important;
}

/* In your CSS file or inside a <style> tag */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* For Firefox */
}